<template>
	<Modal
		title="文件审核"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<!--@on-cancel="onCancel"-->
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<FormItem label="是否通过" prop="isPass">
				<RadioGroup v-model="formData.isPass">
					<Radio :label="1">通过</Radio>
					<Radio :label="0">不通过</Radio>
				</RadioGroup>
			</FormItem>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" :loading="loading" @click="onOk">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/processDoc"

const { auditFile } = api
export default {
	name: "Review",
	data() {
		return {
			loading: false,
			formData: {},
			rules: {
				isPass: {
					required: true,
					type: "number",
					message: "必填项不能为空",
					trigger: "blur change"
				}
			}
		}
	},
	props: ["visible", "docfileId"],
	watch: {
		visible(val) {
			if (!val) {
				this.$refs.form.resetFields()
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},

		onOk() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					const res = await auditFile(
						Object.assign(
							{
								docfileId: this.docfileId,
								projectId: this.$route.params.projectId
							},
							this.formData
						)
					)
					if (res) {
						this.$emit("onOk")
						this.$Message.success("操作成功！")
					}
				}
			})
		}
	},
	mounted() {}
}
</script>

<style scoped></style>
