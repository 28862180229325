<template>
	<Modal
		title="移动文件"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div>
			<div class="sel">
				<label>带权限移动</label>
				<Select v-model="containsAuth">
					<Option value="1">是</Option>
					<Option value="0">否</Option>
				</Select>
			</div>
			<Tree :data="treeList" ref="fileTree"></Tree>
		</div>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
export default {
	name: "Permission",
	props: ["visible", "sourceDocfileId", "fileOpFunc", "treeList"],
	data() {
		return {
			loading: false,
			containsAuth: "1"
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.containsAuth = "1"
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			const s = this.$refs.fileTree.getSelectedNodes()
			if (s.length) {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await this.fileOpFunc.moveFile({
						projectId: this.$route.params.projectId,
						sourceDocfileId: this.sourceDocfileId,
						targetDocfileId: s[0].id,
						containsAuth: this.containsAuth
					})
					if (res) {
						this.$emit("onOk")
						this.$Message.success("移动成功！")
					}
					this.loading = false
				})
			} else {
				this.$Message.error("未选中目标文件！")
			}
		}
	}
}
</script>

<style scoped lang="less">
.sel {
	margin-bottom: 15px;
	// .ivu-select{
	//   display:inline-block;
	//   width:200px;
	// }
	label {
		color: #333;
		font-weight: 600;
		font-size: 14px;
		display: inline-block;
		//  width: 80px;
		padding-bottom: 10px;
	}
}
</style>
