<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="文件夹名称" prop="name">
						<Input
							v-model.trim="formData.name"
							placeholder="请输入文件夹名称"
						/>
						<div
							v-if="defaultDoc.id === '2'"
							style="font-size: 12px; line-height: 20px"
						>
							<p>
								1. 文件夹名称，长度请限制在英文数字255字符以内，中文85字以内；
							</p>
							<p>2. 文件夹名不能包含下列字符：/ \ : * " | < > ?</p>
						</div>
					</FormItem>
					<FormItem label="是否审核" prop="isAudit" v-if="docfileId === '2'">
						<Select v-model="formData.isAudit" placeholder="请选择">
							<Option label="是" :value="1"></Option>
							<Option label="否" :value="0"></Option>
						</Select>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
export default {
	name: "Add",
	props: ["visible", "docfileId", "addFile", "defaultDoc"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				name: [
					{ required: true, message: "文件夹名称不能为空", trigger: "blur" },
					{ validator: this.validatePass, trigger: "blur" }
				],
				isAudit: {
					required: true,
					type: "number",
					message: "是否审核不能为空",
					trigger: "blur change"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.title = "新建文件夹"
				this.formData.pid = this.docfileId ? this.docfileId : null
			}
		}
	},
	methods: {
		validatePass(rule, value, callback) {
			// 验证文件夹名称
			if (value === "") {
				callback(new Error("请输入文件夹名称"))
			} else if (this.defaultDoc.id === "2") {
				// 流程文档库，文件夹名称，需要校验特殊字符
				const flag = new RegExp('[`*|:"\\\\<>/?]')
				if (flag.test(value)) {
					callback(new Error("文件夹名称不能包含特殊字符"))
				} else if (this.strCode(value) > 255) {
					callback(new Error("文件夹名称过长"))
				} else {
					callback()
				}
			} else {
				callback()
			}
		},
		strCode(str) {
			// 获取字符串的字节数
			let count = 0 // 初始化字节数递加变量并获取字符串参数的字符个数
			if (str) {
				// 如果存在字符串，则执行
				const len = str.length
				for (let i = 0; i < len; i++) {
					// 遍历字符串，枚举每个字符
					if (str.charCodeAt(i) > 255) {
						// 字符编码大于255，说明是双字节字符(即是中文),linux 汉字要算3个字符
						count += 3 // 则累加3个
					} else {
						count++ // 否则递加一次
					}
				}
				return count // 返回字节数
			}
			return 0 // 如果参数为空，则返回0个
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.$route.params.projectId) {
							this.formData.projectId = this.$route.params.projectId
						}
						res = await this.addFile({
							...this.formData
						})
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>
