<template>
	<Modal
		:title="title"
		:value="visible"
		width="800"
		class="version-update-drawer"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			:model="formData"
			ref="form"
			label-position="top"
			style="border-bottom: 1px dashed #c0bebe"
			:rules="rules"
		>
			<FormItem label="用户版本" prop="version">
				<Input
					v-model.trim="formData.version"
					:maxlength="30"
					placeholder="请输入用户版本"
				/>
			</FormItem>
			<FormItem prop="fileList">
				<Upload
					:headers="{ token: $store.state.user.token }"
					:before-upload="beforeUpload"
					action=""
					:accept="IS_ONLY_PDF ? 'application/pdf' : '*'"
					:show-upload-list="true"
				>
					<Button :loading="uploadLoading">{{
						uploadLoading ? "上传中" : "请选择文件"
					}}</Button>
					<div
						@click.stop="
							() => {
								return false
							}
						"
						style="
							color: #ff9800;
							margin-top: 8px;
							font-size: 12px;
							line-height: 20px;
						"
					>
						<p>1. 文件大小建议在2G以内，最大不超过10G；</p>
						<p>
							2.
							文件名称（含后缀名），长度请限制在英文数字255字符以内，中文85字以内
						</p>
					</div>
				</Upload>
				<div>
					<p>当前文件：</p>
					<template v-if="fileList.length">
						<div
							class="file-list-item"
							v-for="(item, index) in fileList"
							:key="index"
						>
							<Icon type="ios-document" /> {{ item.file.name }}
							<Icon class="success" type="md-checkmark" />
							<!--v-if="item.id"-->
							<!--<Icon-->
							<!--v-else-->
							<!--type="md-warning"-->
							<!--class="error"-->
							<!--/>-->
						</div>
					</template>
					<template v-else>
						<span>{{ fileName }}</span>
					</template>
				</div>
			</FormItem>
		</Form>
		<div class="version-history">
			<h3 style="padding: 10px 0">版本记录</h3>
			<CtmsDataGrid
				:height="400"
				:columns="columns"
				:data="listData"
				:page="false"
			>
				<template slot-scope="{ index, row }" slot="name">
					<span @click="selectFile(row)" class="version-file-name">
						{{ row.name }}
					</span>
				</template>
			</CtmsDataGrid>
		</div>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button
				type="primary"
				:loading="loading"
				:disabled="!isCanSubmit"
				@click="onOk"
				>确定</Button
			>
		</div>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:isFullScreen="true"
			:documentSrc="documentSrc"
			@onCancel="documentPreviewVisible = false"
			:id="`processVersionDocumentPreview${versionUpdateObj.sourceId}`"
		></DocumentPreview>
	</Modal>
</template>

<script>
import api from "@/api/project/processDoc"
import docApi from "@/api/document/docfile"
import { formDataToMD5 } from "@/utils/util"
import docType from "@/config/docType"
import moment from "moment"
import { mapState } from "vuex"
import DocumentPreview from "./DocumentPreview.vue"

const { versionNumberUpdate, versionFileUpdate } = api
const { getVersionList, getFileExist } = docApi
export default {
	name: "VersionUpdate",
	props: ["visible", "defaultDoc", "versionUpdateObj"],
	components: {
		DocumentPreview
	},
	data() {
		return {
			loading: false,
			uploadLoading: false,
			title: "",
			formData: {},
			rules: {
				version: {
					required: true,
					message: "版本不能为空",
					trigger: "blur"
				}
			},
			fileList: [],
			columns: [
				{
					title: "文件名称",
					key: "name",
					minWidth: 160,
					slot: "name"
				},
				{
					title: "文件版本",
					key: "version",
					minWidth: 100
				},
				{
					title: "更新人",
					key: "createdBy",
					minWidth: 120
				},
				{
					title: "更新时间",
					key: "versionTime",
					width: 120
				}
			],
			listData: [],
			docType,
			documentPreviewVisible: false,
			documentSrc: "",
			fileName: ""
		}
	},
	computed: {
		...mapState("config", ["IS_ONLY_PDF"]),
		projectId() {
			return this.$route.params.projectId
		},
		isCanSubmit() {
			if (this.fileList.length) {
				return true
			}
			if (this.versionUpdateObj.version === this.formData.version) {
				return false
			}
			return true
		}
	},
	watch: {
		visible(val) {
			if (!val) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.fileList = []
				this.fileName = ""
				this.loading = false
				this.uploadLoading = false
			} else {
				this.title = "更新文件"
				// this.formData = { ...this.versionUpdateObj };
				this.$set(this.formData, "version", this.versionUpdateObj.version)
				this.fileName = this.versionUpdateObj.name
				this.getVersionList()
			}
		}
	},
	methods: {
		async getVersionList() {
			// 文件版本历史记录
			this.loading = true
			const res = await getVersionList({
				sourceId: this.versionUpdateObj.sourceId,
				projectId: this.projectId
			})
			if (res) {
				this.listData = res.data
			}
			this.loading = false
		},
		selectFile(row) {
			this.$asyncDo(async () => {
				const res = await getFileExist(row.id)
				if (res) {
					// 先判断文件存在，再进行预览
					const suffix = row.suffix ? row.suffix.toUpperCase() : ""
					if (this.docType.previewTypes.indexOf(suffix) > -1) {
						this.documentSrc = `${this.$baseUrl}/project/${this.projectId}/docfile/${row.id}/inline?token=${this.$store.state.user.token}`
						this.documentPreviewVisible = true
					} else if (
						this.defaultDoc.id === "1" &&
						(!row.allowDownload || !row.suffix)
					) {
						this.$Message.error("当前用户没有下载权限")
					} else {
						window.open(
							`${this.$baseUrl}/project/${this.projectId}/docfile/${row.id}/download?token=${this.$store.state.user.token}`
						)
					}
				}
			})
		},
		beforeUpload(file) {
			if (this.strCode(file.name) > 255) {
				this.$Message.error(
					"文件名称过长，请限制在英文数字255字符以内，中文85字以内（含后缀名）!"
				)
				return false
			}
			const size = file.size / 1024
			const filemaxsize = 1024 * 1024 * 10 // 10G
			if (size > filemaxsize) {
				this.$Message.error("附件大小不能大于10G")
				return false
			}
			this.uploadLoading = true
			this.loading = true
			this.fileList = []
			formDataToMD5(file, md5Str => {
				this.fileList.push({ file, md5: md5Str })
				this.uploadLoading = false
				this.loading = false
			})
			return false
		},
		strCode(str) {
			// 获取字符串的字节数
			let count = 0 // 初始化字节数递加变量并获取字符串参数的字符个数
			if (str) {
				// 如果存在字符串，则执行
				const len = str.length
				for (let i = 0; i < len; i++) {
					// 遍历字符串，枚举每个字符
					if (str.charCodeAt(i) > 255) {
						// 字符编码大于255，说明是双字节字符(即是中文),linux 汉字要算3个字符
						count += 3 // 则累加3个
					} else {
						count++ // 否则递加一次
					}
				}
				return count // 返回字节数
			}
			return 0 // 如果参数为空，则返回0个
		},
		getFormData() {
			const formData = new FormData()
			formData.append(
				"file",
				this.fileList && this.fileList.length ? this.fileList[0].file : null
			)
			// formData.append('md5',  this.fileList[0].md5);
			// formData.append('version', this.formData.version);
			// formData.append('docfileId', this.docfileId);
			return versionFileUpdate(
				{
					docfileId: this.versionUpdateObj.id,
					projectId: this.projectId,
					md5:
						this.fileList && this.fileList.length ? this.fileList[0].md5 : "",
					version: this.formData.version
				},
				formData
			)
		},
		onOk() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					// 更新文件
					if (this.fileList.length) {
						const itemC = this.listData.find(
							item => item.name === this.fileList[0].file.name
						)
						if (itemC) {
							this.$Modal.confirm({
								title: "提示",
								content: `版本历史文件中已包含一个名为"${itemC.name}"的文件，是否进行替换？`,
								onOk: () => {
									this.$Modal.remove()
									this.submit()
								}
							})
						} else {
							this.submit()
						}
					} else {
						this.submit()
					}
					// } else {
					//   this.loading = true;
					//   // 修改版本号
					//   const res = await versionNumberUpdate({
					//     docfileId: this.versionUpdateObj.id,
					//     projectId: this.projectId,
					//     newVersion: this.formData.version,
					//   });
					//   if (res) {
					//     this.$emit('onOk');
					//     this.$Message.success('操作成功！');
					//   }
					//   this.loading = false;
					// }
				}
			})
		},
		async submit() {
			this.loading = true
			const res = await this.getFormData()
			if (res) {
				this.$emit("onOk")
				this.$Message.success("操作成功！")
			}
			this.loading = false
		},
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style lang="less">
@import "../../../my-theme/color.less";
.version-update-drawer {
	.ivu-drawer-body .ivu-form-label-top .ivu-form-item-label {
		font-weight: 400;
	}
	.file-list-item {
		padding: 5px;
		font-size: 13px;
	}
	.file-list-item .ivu-icon {
		vertical-align: middle;
		margin-top: -3px;
	}
	.file-list-item .success {
		color: #19be6b;
		margin-left: 5px;
	}
	.file-list-item .error {
		color: #ed4014;
		margin-left: 5px;
	}
	.version-history {
		.version-file-name {
			cursor: pointer;
			&:hover {
				color: @primary-color;
			}
		}
	}
}
</style>
