<template>
	<Modal
		title="排序"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<vueDraggable
			v-model="draggableObj.sortObj"
			:options="{ group: 'dataitem' }"
		>
			<div
				class="document-process-sort-item"
				v-for="(item, index) in draggableObj.sortObj"
				:key="index"
			>
				{{ item.name }}
			</div>
		</vueDraggable>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>
<script>
import vueDraggable from "vuedraggable"

export default {
	props: {
		sortList: {
			type: Array,
			default: () => []
		},
		visible: {
			type: Boolean,
			default: false
		},
		sortPid: {
			type: String,
			default: ""
		},
		fileOpFunc: {
			type: Object,
			default: () => {}
		},
		defaultDoc: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		vueDraggable
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$set(this.draggableObj, "sortObj", this.sortList)
			}
		}
	},
	data() {
		return {
			loading: false,
			draggableObj: {
				sortObj: []
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		async onOk() {
			this.loading = true
			const docfileIds = this.draggableObj.sortObj.map(item => item.id)
			let res = false
			if (this.defaultDoc.id === "2") {
				// 流程文档库
				res = await this.fileOpFunc.sortFile({
					projectId: this.$route.params.projectId,
					docfileIds
				})
			} else if (this.defaultDoc.id === "1") {
				// 资料中心
				res = await this.fileOpFunc.sortFile({
					docfileIds
				})
			}
			if (res) {
				this.loading = false
				this.$emit("onOk")
				this.$emit("onCancel")
			}
			this.loading = false
		}
	}
}
</script>
<style>
.document-process-sort-item {
	padding: 8px 10px;
	color: #515a6e;
	font-size: 14px;
	border-bottom: solid 1px #e5e5e5;
}
</style>
