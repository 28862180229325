<template>
	<div class="content-wrap document-body">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="文件名称">
				<Input placeholder="请输入文件名称" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<Row class="doc-top" v-if="defaultDoc.type !== 'view'">
			<Col span="13">
				<span class="default-name">{{ defaultDoc.name }}</span>
				<span v-if="defaultDoc.id === '1'" class="warn"
					>（仅用于存放医院标准操作规程等告示文件，请勿上传项目文件）</span
				>
			</Col>
			<Col span="11" class="doc-action-btn">
				<Tooltip content="上传文件" v-if="!(!isRoot && rootFolderId)">
					<span @click="uploadFileShow" v-if="uploadPerssion">
						<CtmsIcon type="icon-shangchuanwenjian" :size="34"></CtmsIcon>
					</span>
				</Tooltip>
				<Tooltip content="新建文件夹" v-if="!(!isRoot && rootFolderId)">
					<span @click="handleAddShow" v-if="addPermission">
						<CtmsIcon type="icon-tianjiawendang" :size="34"> </CtmsIcon>
					</span>
				</Tooltip>
				<Tooltip content="一键打包" v-if="defaultDoc.id === '2'">
					<span @click="handleTag">
						<Icon class="tag" type="ios-briefcase-outline" :size="34" />
					</span>
				</Tooltip>
				<Tooltip
					content="排序"
					v-if="
						!oldSearchParams.name &&
						listData.length &&
						(defaultDoc.id === '1' || defaultDoc.id === '2') &&
						!(!isRoot && rootFolderId)
					"
				>
					<span @click="handleSort">
						<CtmsIcon type="icon-paixu" :size="34"></CtmsIcon>
					</span>
				</Tooltip>
			</Col>
		</Row>
		<Row class="action-row">
			<Col span="19">
				<span
					v-for="(item, index) in pathList"
					:key="index"
					@click="onSelectDoc(item)"
				>
					<span class="line" v-show="index > 0">/</span>
					<span :class="[{ active: item.id === docfileId }, 'pathItem']">
						{{ item.name }}
					</span>
				</span>
				<span v-show="oldSearchParams.name">
					<span class="line">/</span>
					<span class="active pathItem"> 搜索文件 </span>
				</span>
			</Col>
		</Row>
		<!-- 文件列表 列表展示 -->
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="allColumns"
			:data="allListData"
			:page="false"
			@on-sort-change="handleSortChange"
		>
			<template slot-scope="{ row }" slot="status">
				<Tooltip :content="getStatus(row.status).tooltip">
					<span
						v-if="getStatus(row.status).type === 'span'"
						:style="getStatus(row.status).style"
					>
						{{ getStatus(row.status).content }}
					</span>
					<img
						v-else
						:style="getStatus(row.status).style"
						:src="getStatus(row.status).content"
					/>
				</Tooltip>
			</template>
		</CtmsDataGrid>
		<!-- 文件列表展示 -->
		<!-- 新增文件夹 -->
		<Add
			:defaultDoc="defaultDoc"
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:docfileId="docfileId"
			:addFile="fileOpFunc.addFile"
		></Add>
		<!-- 上传文件 -->
		<UploadFile
			:visible="repVisible"
			@onCancel="repVisible = false"
			@onOk="handleReplaceOk"
			:docfileId="docfileId"
			:replaceId="replaceId"
			:fileOpFunc="fileOpFunc"
			:multiple="multiple"
			:defaultDoc="defaultDoc"
			:pid="pid"
			:isRoot="isRoot"
		></UploadFile>
		<!-- 上传文件 -->
		<!-- 权限设置 -->
		<!--流程文档库权限限制-->
		<Permission
			:visible="permissionVisible"
			@onCancel="permissionVisible = false"
			@onOk="handleSetPermissionOk"
			:id="permissionId"
			:pid="pid"
			:fileOpFunc="fileOpFunc"
			:isRoot="isRoot"
		></Permission>
		<!--资料中心权限设置-->
		<InfoPermission
			:visible="infoPermissionVisible"
			@onCancel="infoPermissionVisible = false"
			@onOk="handleSetInfoPermissionOk"
			:type="grantType"
			:id="permissionId"
			:pid="pid"
			:isRoot="isRoot"
			:fileOpFunc="fileOpFunc"
		></InfoPermission>
		<!-- 权限设置 -->
		<!--表单预览-->
		<Preview
			:visible="previewVisible"
			type="document"
			@onCancel="previewVisible = false"
			:id="previewId"
		></Preview>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="'pubDocumentPreview'"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
		<!-- 移动文件 -->
		<documentMove
			:visible="moveVisible"
			:sourceDocfileId="sourceDocfileId"
			:treeList="treeList"
			:fileOpFunc="fileOpFunc"
			@onCancel="moveVisible = false"
			@onOk="moveOk"
		></documentMove>
		<!-- 移动文件 -->

		<!--修改文件 及 版本号 查看版本记录-->
		<VersionUpdate
			:visible="versionVisible"
			:defaultDoc="defaultDoc"
			:versionUpdateObj="versionUpdateObj"
			@onCancel="versionVisible = false"
			@onOk="versionUpdateOk"
		></VersionUpdate>
		<!--:docfileId="sourceDocfileId"-->
		<!--:sourceId="versionHistorySourceId"-->
		<!--:versionTime="versionTime"-->
		<!--:version="version"-->
		<!--:versionType="versionType"-->
		<!--审核-->
		<Review
			:visible="auditVisible"
			:docfileId="sourceDocfileId"
			@onCancel="auditVisible = false"
			@onOk="auditOk"
		></Review>
		<!--版本记录-->
		<VersionHistoryList
			:visible.sync="versionHistoryVisible"
			:sourceId="versionHistorySourceId"
			:entry="defaultDoc.id"
		></VersionHistoryList>
		<!--文件排序-->
		<documentSort
			:sortList="listData"
			:visible="sortVisible"
			:sortPid="sortPid"
			:fileOpFunc="fileOpFunc"
			:defaultDoc="defaultDoc"
			@onCancel="sortVisible = false"
			@onOk="initList"
		>
		</documentSort>
	</div>
</template>

<script>
import { mapState } from "vuex"
import { transformSizeUnit, transformTreeData } from "@/utils/util"
import Add from "./Add.vue"
import Preview from "@/views/Setting/Form/Preview.vue"
import docType from "@/config/docType"
import docFileImg from "@/assets/common/docfile.png"
import PdfImg from "@/assets/common/pdf.png"
import PdfImgDel from "@/assets/common/pdf-del.png"
import photoImg from "@/assets/common/photo.png"
import photoImgDel from "@/assets/common/img-del.png"
import excelImg from "@/assets/common/excel.png"
import excelImgDel from "@/assets/common/excel-del.png"
import docImg from "@/assets/common/doc.png"
import docImgDel from "@/assets/common/doc-del.png"
import txtImg from "@/assets/common/txt.png"
import txtImgDel from "@/assets/common/txt-del.png"
import delDocImg from "@/assets/common/deldoc.png"
import "./indexList.less"
// import ExpandList from './expandList.vue';
import orangeImg from "@/assets/images/project/light1.svg"
import yellowImg from "@/assets/images/project/light2.svg"
import redImg from "@/assets/images/project/light3.svg"
import greenImg from "@/assets/images/project/light4.svg"
import VersionHistoryList from "./VersionHistoryList.vue"
import Review from "./Review.vue"
import VersionUpdate from "./VersionUpdate.vue"
import documentSort from "./DocumentSort.vue"
import documentMove from "./documentMove.vue"
import DocumentPreview from "./DocumentPreview.vue"
import InfoPermission from "./InfoPermission.vue"
import UploadFile from "./UploadFile.vue"
import Permission from "./Permission.vue"

export default {
	name: "index",
	props: [
		"fileOpFunc", // 处理文件的方法（上传，新增，删除，替换，配置权限）
		"defaultDoc", // 默认的文件类型、文件id、文件名称
		"previewUrl", // 预览路径
		"downloadUrl", // 下载路径
		"multiple", // 多个上传还是单个文件上传
		"projectId", // 项目id, 除了资料中心外，其他引用页面基本都有项目ID
		"tableHeight" // 表格高度
	],
	components: {
		Add,
		UploadFile,
		Permission,
		InfoPermission,
		Preview,
		DocumentPreview,
		documentSort,
		documentMove,
		// ExpandList,
		VersionUpdate,
		Review,
		VersionHistoryList
	},
	data() {
		return {
			user: this.$store.state.user.token,
			grantType: "view", // view 为查看权限, download 为下载权限.
			treeList: [],
			extraData: {
				pid: ""
			},
			listData: [],
			delFiles: [],
			allColumns: [],
			columns: [
				{
					title: "名称",
					key: "name",
					minWidth: 250,
					sortable: "custom",
					sortType: "",
					render: this.selDocment
				},
				{
					title: "大小",
					render: (h, params) =>
						h(
							"span",
							params.row.size ? `${transformSizeUnit(params.row.size)}` : "--"
						),
					minWidth: 100
				},
				{
					title: "创建日期",
					minWidth: 100,
					key: "createdTime"
				}
			],
			readColumn: [
				{
					title: "权限状态",
					minWidth: 100,
					key: "roleIds ",
					render: (h, params) =>
						h("span", params.row.roleIds ? "已配置" : "未配置")
				},
				{
					title: "文件版本",
					key: "version",
					minWidth: 100,
					align: "center",
					render: (h, { row }) => h("span", row.version || "--")
				},
				{
					title: "文件状态",
					key: "status",
					minWidth: 100,
					align: "center",
					slot: "status"
				},
				{
					title: "文件夹状态",
					key: "isAudit",
					minWidth: 100,
					align: "center",
					render: (h, { row }) => {
						// 1是文件 2是文件夹 3是表单文件
						if (row.type === 1 || row.type === 3) {
							return h("span", "--")
						}
						if (row.isAudit === 0) {
							return h("span", "不需要审核")
						}
						if (row.isAudit === 1) {
							return h("span", "需要审核")
						}
						return h("span", "--")
					}
				}
			],
			actionColumn: [
				{
					title: "操作",
					key: "action",
					width: 300,
					// fixed: 'right',
					renderButton: params => {
						const btnList = [
							{
								label: "查看权限", // 0
								on: {
									click: () => {
										this.authorityAllocation(params.row, "view")
									}
								}
							},
							{
								label: "下载权限", // 1
								on: {
									click: () => {
										this.authorityAllocation(params.row, "download")
									}
								}
							},
							{
								label: "打包文件", // 2
								on: {
									click: () => {
										this.packageFiles(params.row)
									}
								}
							},
							{
								label: "删除", // 3
								on: {
									click: () => {
										this.handleSwitchState(params.row)
									}
								},
								confirm: {
									title: "您确认删除该文件吗？"
								}
							},
							{
								label: "移动", // 4
								on: {
									click: () => {
										this.moveFile(params.row)
									}
								}
							},
							{
								label: "文件下载", // 5
								on: {
									click: () => {
										let downloadUrl = ""
										if (this.defaultDoc.id === "1") {
											// 资料中心
											downloadUrl = `${this.$baseUrl}/document/docfile/${params.row.id}/download?token=${this.$store.state.user.token}`
										} else if (this.defaultDoc.id === "2") {
											// 流程文档库
											downloadUrl = `${this.$baseUrl}/project/${this.projectId}/docfile/${params.row.id}/download?token=${this.$store.state.user.token}`
										} else if (this.defaultDoc.id === "3") {
											// 合同资料
											downloadUrl = `${this.$baseUrl}/project/${this.projectId}/contract/docfile/${params.row.id}/download?token=${this.$store.state.user.token}`
										} else if (this.defaultDoc.id === "6") {
											// 遗传办
											downloadUrl = `${this.$baseUrl}/project/${this.projectId}/genetic/docfile/${params.row.id}/download?token=${this.$store.state.user.token}`
										}
										window.open(downloadUrl)
									}
								}
							},
							{
								label: "审核", // 6
								on: {
									click: () => this.auditFile(params.row)
								}
							},
							{
								label: "版本更新", // 7
								on: {
									click: () => this.updateVersion(params.row)
								}
							},
							// {
							//   label: '修改', // 8
							//   on: {
							//     click: () => this.editFile(params.row),
							//   },
							// },
							// {
							//   label: '版本记录', // 9
							//   on: {
							//     click: () => this.showVersionHistory(params.row),
							//   },
							// },
							{
								label: "清空文件夹", // 8
								on: {
									click: () => {
										this.emptyFiles(params.row)
									}
								},
								confirm: {
									title: "您确认清空该文件夹吗？"
								}
							}
						]

						// btnlist: 1、下载权限、 2、压缩文件  3、删除 4、移动 9.版本记录
						if (this.defaultDoc.id === "1" || this.defaultDoc.id === "3") {
							// id为1，资料中心；id为2 ,流程文档库; id为3，合同管理
							btnList[2] = null
							btnList[4] = null
							btnList[6] = null
							btnList[7] = null
							// btnList[8] = null;
							// btnList[9] = null;
						}
						if (this.defaultDoc.id !== "1") {
							// 仅在 资料中心 页面显示 查看权限 按钮
							btnList[0] = null
						}
						if (this.defaultDoc.id !== "6") {
							// 仅在 遗传办文档库 页面显示 清空文件夹 按钮
							btnList[8] = null
						}
						if (params.row.type !== 2) {
							btnList[2] = null
						}
						if (params.row.seq === -1 || (!this.isRoot && this.rootFolderId)) {
							btnList[0] = null
							btnList[3] = null
							btnList[4] = null
							btnList[6] = null
							btnList[7] = null
							btnList[8] = null
							if (params.row.type === 2) {
								btnList[5] = null
							} else {
								btnList[1] = null
								btnList[2] = null
							}
						}
						// 权限设置

						// 资料中心
						if (
							this.systemActionPermissions.indexOf("btn_document_del") === -1 &&
							this.defaultDoc.id === "1"
						) {
							btnList[3] = null
						}
						// 流程文档库
						if (this.defaultDoc.id === "2") {
							if (
								this.projectActionPermissions.indexOf(
									"btn_project_document_del"
								) === -1
							) {
								btnList[3] = null
							}
							if (
								this.projectActionPermissions.indexOf(
									"btn_project_document_role"
								) === -1
							) {
								btnList[1] = null
							}
							if (
								this.projectActionPermissions.indexOf(
									"btn_project_document_zip"
								) === -1
							) {
								btnList[2] = null
							}
							if (
								this.projectActionPermissions.indexOf(
									"btn_project_document_move"
								) === -1
							) {
								btnList[4] = null
							}
							if (
								this.projectActionPermissions.indexOf(
									"btn_project_document_file_audit"
								) === -1 ||
								params.row.status !== 1
							) {
								btnList[6] = null
							}
							if (
								!this.uploadPerssion ||
								(params.row.status !== 2 && params.row.status !== 4)
							) {
								btnList[7] = null
							}
							// if (params.row.type !== 1) {
							//   btnList[8] = null;
							// }
						}
						// 遗传办文档库 只有删除 清空文件夹 文件下载
						if (this.defaultDoc.id === "6") {
							btnList[1] = null
							btnList[2] = null
							btnList[4] = null
							btnList[6] = null
							btnList[7] = null
							// btnList[8] = null;
							// btnList[9] = null;
							if (params.row.type !== 1) {
								btnList[3] = null // 删除
								btnList[5] = null // 文件下载
							} else {
								btnList[8] = null // 清空文件夹
							}
						}
						// 合同管理
						if (this.defaultDoc.id === "3") {
							btnList[1] = null
							if (
								this.projectActionPermissions.indexOf(
									"btn_project_contract_del"
								) === -1
							) {
								btnList[3] = null
							}
						}
						// 下载文件
						if (
							!params.row.suffix ||
							(this.defaultDoc.id === "1" && !params.row.allowDownload)
						) {
							btnList[5] = null
						}
						// 资料中心权限分配
						if (
							this.defaultDoc.id === "1" &&
							this.systemActionPermissions.indexOf("btn_document_role") === -1
						) {
							btnList[0] = null
							btnList[1] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			permissionVisible: false,
			loading: false,
			addVisible: false,
			repVisible: false,
			logsVisible: false,
			upFileVisible: false,
			docfileId: this.defaultDoc.id,
			pid: this.defaultDoc.id,
			replaceId: "",
			permissionId: "",
			searchParams: {
				name: ""
			},
			oldSearchParams: {
				name: ""
			},
			pathList: [this.defaultDoc],
			oldPathList: [this.defaultDoc],
			isEnableRootFolder: false, // 根目录是否是 在 审查通过文件夹下
			rootFolderId: "", // 第一层级时，审查通过文件夹id ，没有审查通过文件夹，则为空。
			previewVisible: false,
			previewId: "",
			documentPreviewVisible: false,
			documentSrc: "",
			imgPreviewVisible: false,
			imgSrc: "",
			sourceDocfileId: "",
			moveVisible: false,
			optionDoc: {},
			isOption: false,
			infoPermissionVisible: false,
			//  版本更新
			versionVisible: false,
			versionType: 1, // 1 是 版本更新 2 是版本号更新
			version: "",
			// versionTime: '',
			auditVisible: false,
			versionHistoryVisible: false,
			versionHistorySourceId: "",
			sortVisible: false,
			sortPid: "",
			isFolder: true,
			confirmLoading: false,
			versionUpdateObj: {} // 更新文件及版本
		}
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState("permission", [
			"systemActionPermissions",
			"projectActionPermissions"
		]),
		...mapState({
			dataGridHeight(state) {
				if (this.tableHeight) {
					return this.tableHeight
				}
				if (this.projectId) {
					return state.contentHeight - 188 - this.infoHeight
				}
				return state.contentHeight - 188
			}
		}),
		isRoot() {
			return this.pathList && this.pathList.length === 1
		},
		// 新增、创建权限
		addPermission() {
			if (
				this.systemActionPermissions.indexOf("btn_document_add") === -1 &&
				this.defaultDoc.id === "1"
			) {
				return false
			}
			if (
				this.projectActionPermissions.indexOf("btn_project_document_add") ===
					-1 &&
				this.defaultDoc.id === "2"
			) {
				return false
			}
			if (
				this.projectActionPermissions.indexOf("btn_project_contract_add") ===
					-1 &&
				this.defaultDoc.id === "3"
			) {
				return false
			}
			if (this.defaultDoc.id === "6") {
				return false
			}
			return true
		},
		// 上传权限按钮
		uploadPerssion() {
			let res = true
			if (
				this.projectActionPermissions.indexOf("btn_project_document_add") ===
					-1 &&
				this.defaultDoc.id === "2"
			) {
				res = false
			}
			if (
				this.systemActionPermissions.indexOf("btn_document_add") === -1 &&
				this.defaultDoc.id === "1"
			) {
				res = false
			}
			if (
				this.projectActionPermissions.indexOf("btn_project_contract_add") ===
					-1 &&
				this.defaultDoc.id === "3"
			) {
				res = false
			}
			if (this.defaultDoc.id === "6") {
				if (!this.isFolder) {
					res = true
				} else {
					res = false
				}
			}
			return res
		},
		// 删除按钮权限
		delPermission() {
			let del = true
			if (
				this.systemActionPermissions.indexOf("btn_document_del") === -1 &&
				this.defaultDoc.id === "1"
			) {
				del = false
			}
			if (
				this.projectActionPermissions.indexOf("btn_project_document_del") ===
					-1 &&
				this.defaultDoc.id === "2"
			) {
				del = false
			}
			if (
				this.projectActionPermissions.indexOf("btn_project_contract_del") ===
					-1 &&
				this.defaultDoc.id === "3"
			) {
				del = false
			}
			if (this.defaultDoc.id === "6") {
				if (!this.isFolder) {
					del = true
				} else {
					del = false
				}
			}
			return del
		},
		// 清空文件夹 按钮权限
		emptyPermission() {
			let del = false
			if (this.defaultDoc.id === "6" && this.isFolder) {
				del = true
			}
			return del
		},
		// 数组
		allListData() {
			if (this.defaultDoc.id === "1") {
				return [...this.listData, ...this.delFiles]
			}
			return [...this.listData]
		}
	},
	created() {
		this.initList()
		this.allColumns = []
		if (this.defaultDoc.id === "2") {
			// 流程文档库
			if (this.defaultDoc.type === "view") {
				// type:view 只进行查看，不进行操作
				this.allColumns = [...this.columns, ...this.readColumn]
			} else {
				this.allColumns = [
					...this.columns,
					...this.readColumn,
					...this.actionColumn
				]
				if (
					this.projectId &&
					this.projectActionPermissions.indexOf("btn_project_document_move") >
						-1
				) {
					this.getTree() // 移动文件
				}
			}
		} else {
			this.actionColumn[0].fixed = "right"
			this.allColumns = [...this.columns, ...this.actionColumn]
		}
	},
	methods: {
		initList() {
			this.listData = []
			this.delFiles = []
			this.$asyncDo(async () => {
				this.loading = true
				const res = await this.fileOpFunc.apiGetPage({
					...this.oldSearchParams,
					pid: this.docfileId,
					projectId: this.projectId
				})
				if (res) {
					if (this.defaultDoc.id === "1") {
						this.listData = res.data ? res.data.docfiles : []
						this.delFiles = res.data ? res.data.delDocfiles : []
					} else {
						this.listData = res.data
					}
					this.$nextTick(() => {
						if (this.oldSearchParams.name) {
							// 搜索时，默认还是原来的目录
							this.pathList = [...this.oldPathList]
						} else if (this.allListData.length) {
							this.pathList = JSON.parse(this.allListData[0].namePath)
							this.pathList = this.pathList.splice(0, this.pathList.length - 1)
						}
						this.oldPathList = [...this.pathList]
						if (this.defaultDoc.id === "6") {
							if (this.allListData.length) {
								this.isFolder = !!(this.allListData[0].type === 2)
							} else {
								this.isFolder = false
							}
						}
					})
				}
				this.loading = false
			})
		},
		getStatus(status) {
			const obj = {
				type: "span", // 显示 span,  显示img
				content: "--", // 显示内容
				tooltip: "无需审核" // tooltip内容
			}
			switch (status) {
				case 0:
					obj.type = "span"
					obj.content = "--"
					obj.tooltip = "无需审核"
					break
				case 1:
					obj.type = "img"
					obj.content = yellowImg
					obj.tooltip = "待审核"
					break
				case 2:
					obj.type = "img"
					obj.content = greenImg
					obj.tooltip = "审核通过"
					break
				case 3:
					obj.type = "span"
					obj.content = "X"
					obj.tooltip = "审核不通过"
					break
				case 4:
					obj.type = "img"
					obj.content = orangeImg
					obj.tooltip = "流程中文件"
					break
				case 5:
					obj.type = "img"
					obj.content = redImg
					obj.tooltip = "被版本更新文件"
					break
				default:
					obj.type = "span"
					obj.content = "--"
					obj.tooltip = "无需审核"
					break
			}

			if (obj.type === "img") {
				obj.style = {
					width: "50px",
					height: "50px",
					padding: "8px"
				}
			} else if (status === 3) {
				obj.style = {
					color: "#f00",
					fontSize: "20px"
				}
			}
			return obj
		},
		getTree() {
			this.$asyncDo(async () => {
				this.loading = true
				const ret = await this.fileOpFunc.getTreeList({
					projectId: this.projectId
				})
				if (ret) {
					this.treeList = transformTreeData(ret.data)
				}

				this.loading = false
			})
		},
		// 设置权限
		handleSetPermissionOk() {
			this.permissionVisible = false
			this.initList()
		},
		// 设置权限
		handleSetInfoPermissionOk() {
			this.infoPermissionVisible = false
			this.initList()
		},
		// 移动文件
		moveFile(row) {
			this.moveVisible = true
			this.sourceDocfileId = row.id
		},
		moveOk() {
			this.moveVisible = false
			this.initList()
		},
		// 替换文件成功
		handleReplaceOk() {
			this.repVisible = false
			this.initList()
		},
		// 文件夹、文件展示
		selDocment(h, params) {
			let imgSrc = docFileImg
			const docTypes = Object.keys(docType)
			let fileType = ""

			docTypes.forEach(key => {
				if (
					docType[key].find(
						o =>
							o === (params.row.suffix ? params.row.suffix.toUpperCase() : "")
					)
				) {
					fileType = key
				}
			})
			if (params.row.type === 2) {
				imgSrc = params.row.isDel ? delDocImg : docFileImg
			} else {
				// 根据文件类型展示图片
				switch (fileType.toUpperCase()) {
					case "PDF":
						imgSrc = params.row.isDel ? PdfImgDel : PdfImg
						break
					case "PHOTO":
						imgSrc = params.row.isDel ? photoImgDel : photoImg
						break
					case "EXCEL":
						imgSrc = params.row.isDel ? excelImgDel : excelImg
						break
					case "TXT":
						imgSrc = params.row.isDel ? txtImgDel : txtImg
						break

					default:
						imgSrc = params.row.isDel ? docImgDel : docImg
						break
				}
			}
			return h(
				"div",
				{
					style: {
						paddingLeft: "40px",
						position: "relative",
						cursor: "pointer"
					}
				},
				[
					h("img", {
						attrs: {
							src: imgSrc
						},
						style: {
							width: "40px",
							padding: "8px",
							position: "absolute",
							left: "0",
							top: "0"
						},
						on: {
							click: () => {
								this.onSelectDoc(params.row)
							}
						}
					}),

					h(
						"div",
						{
							style: {
								cursor: "pointer",
								padding: "8px 0 8px 0"
							},
							title: params.row.name,
							on: {
								click: () => {
									this.onSelectDoc(params.row)
								},
								mouseover: e => {
									e.srcElement.style.color = "#2D8cF0"
								},
								mouseleave: e => {
									e.srcElement.style.color = "#515a6e"
								}
							}
						},
						params.row.name
					)
				]
			)
		},
		// 选中文件夹、文件
		onSelectDoc(row) {
			this.pid = row.id
			this.isOption = false
			this.extraData.pid = row.id
			// 根据数据的type来判断文件类型
			// 1：上传的文件
			// 2：文件夹
			// 3：流程保存的表单html
			switch (row.type) {
				case 1:
					// if ((this.defaultDoc.id === '1' && !row.allowDownload)) {
					//   this.$Message.error('当前用户没有下载权限');
					//   return false;
					// }
					const suffix = row.suffix ? row.suffix.toUpperCase() : ""
					if (docType.previewTypes.indexOf(suffix) > -1) {
						this.documentPreviewVisible = true
						this.documentSrc = `${this.previewUrl}/${row.id}/inline?token=${this.$store.state.user.token}`
					} else {
						this.$Message.error("当前文件格式不支持预览") // 不支持预览时，不允许下载
						// if (this.defaultDoc.id === '1') { // 资料中心预览时候，不允许下载
						//   this.$Message.error('当前文件格式不支持预览');
						// } else {
						//   window.open(`${this.downloadUrl}/${row.id}/download?token=${this.$store.state.user.token}`);
						// }
					}
					break
				case 2:
					// 流程文档库下，为根目录时，判断sep = -1 来定位 审查通过文件夹；
					if (this.isRoot && this.defaultDoc.id === "2") {
						// sep === -1
						// this.isEnableRootFolder = !!(row.seq === -1);
						if (row.seq === -1) {
							this.rootFolderId = row.id
						} else {
							this.rootFolderId = ""
						}
					}
					// 选中文件夹时，清空搜索条件；预览时，不需要清空
					this.oldSearchParams = {
						name: ""
					}
					this.searchParams = {
						name: ""
					}
					this.docfileId = row.id
					if (row.namePath) {
						this.pathList = JSON.parse(row.namePath)
						this.oldPathList = [...this.pathList]
					}
					this.initList()
					break
				case 3:
					this.previewVisible = true
					this.previewId = row.entityId
					break
				default:
					break
			}
		},
		// 展开样式选中文件进行编辑
		selectExpandDocument(obj, isOption) {
			this.optionDoc = obj || {}
			this.isOption = isOption
		},
		// 清空文件夹
		async emptyFiles(row) {
			const res = await this.fileOpFunc.emptyFile({
				projectId: this.projectId,
				docfileId: row.id
			})
			if (res) {
				this.$Message.success("文件夹已清空！")
				this.initList()
			} else {
				this.loading = false
			}
		},
		// 替换文件
		replaceFile(row) {
			this.replaceId = row.id
			this.repVisible = true
		},
		// 上传文件
		uploadFileShow() {
			this.repVisible = true
			this.replaceId = ""
		},
		// 下载权限
		authorityAllocation(row, type) {
			this.permissionId = row.id
			this.grantType = type
			if (this.defaultDoc.id === "1") {
				// 资料中心
				this.infoPermissionVisible = true
			} else if (this.defaultDoc.id === "2") {
				// 流程文档库
				this.permissionVisible = true
			}
		},

		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},

		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 删除文件
		handleSwitchState(row) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await this.fileOpFunc.deleteFile({
					projectId: this.projectId,
					docfileId: row.id
				})
				if (res) {
					this.$Message.success("删除成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 版本更新
		updateVersion(row) {
			// this.versionType = 1;
			// this.sourceDocfileId = row.id;
			// this.version = row.version;
			// this.versionHistorySourceId = row.sourceId;
			this.versionUpdateObj = row
			this.versionVisible = true
			// this.versionTime = row.versionTime;
		},
		// 修改
		editFile(row) {
			this.versionUpdateObj = row
			this.versionVisible = true
			// this.versionType = 2;
			// this.sourceDocfileId = row.id;
			// this.version = row.version;
			// this.versionTime = row.versionTime;
		},

		versionUpdateOk() {
			this.versionVisible = false
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 显示版本记录
		showVersionHistory({ sourceId }) {
			this.versionHistoryVisible = true
			this.versionHistorySourceId = sourceId
		},
		// 审核
		auditFile(row) {
			this.sourceDocfileId = row.id
			this.auditVisible = true
		},
		auditOk() {
			this.auditVisible = false
			this.$nextTick(() => {
				this.initList()
			})
		},
		handleSort() {
			this.sortPid = this.docfileId
			this.sortVisible = true
		},
		// 排序
		handleSortChange({ key, order }) {
			this.oldSearchParams.orderType = order
			this.oldSearchParams.orderCode = key
			this.$set(this.allColumns, 0, {
				title: "名称",
				key: "name",
				minWidth: 300,
				sortable: "custom",
				sortType: order,
				render: this.selDocment
			})
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 打包整个流程文档库
		handleTag() {
			this.confirmLoading = true
			this.$Modal.confirm({
				title: "提示",
				content:
					"您确定要一键打包整个流程文档库吗？<br/>如需打包，点击确定后，请在打包文件列表进行下载！",
				loading: this.confirmLoading,
				onOk: async () => {
					const res = await this.fileOpFunc.downloadZip({
						projectId: this.projectId
					})
					if (res) {
						this.$Modal.remove()
						this.$Message.success("打包成功")
					}
				}
			})
		},
		// 打包文件
		packageFiles(row) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await this.fileOpFunc.packageFile({
					projectId: this.projectId,
					docfileId: row.id
				})
				if (res) {
					this.$Message.success("操作成功")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				name: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)

			// this.docfileId = '';
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
<style lang="less">
.document-body {
	.doc-top {
		.btn {
			margin-right: 10px;
		}
		.tag {
			font-weight: bold;
		}
	}
}
</style>
