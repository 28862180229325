<template>
	<Modal
		:title="title"
		:value="visible"
		width="400"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="upload-file-wrap"
	>
		<Spin fix v-if="loading && $route.name !== 'projectDocumentProcess'"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<!-- 流程文档库下 显示,并且不能是根目录下-->
				<Col span="24" v-if="defaultDoc.id === '2' && !isRoot">
					<FormItem label="是否上传文件到对应流程" prop="isRelateProcess">
						<i-switch
							v-model="formData.isRelateProcess"
							:true-value="1"
							:false-value="0"
							@on-change="checked => switchChange(checked, 'isRelateProcess')"
							>>
							<span slot="open">是</span>
							<span slot="close">否</span>
						</i-switch>
					</FormItem>
				</Col>
				<Col span="24" v-if="formData.isRelateProcess === 1">
					<FormItem label="选择对应流程" prop="processIds">
						<Cascader
							v-model="formData.processIds"
							:transfer="true"
							@on-change="changeCascader"
							:data="processList"
							:not-found-text="`无匹配数据，请检查流程是否启动`"
							filterable
						></Cascader>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="用户版本" prop="version">
						<Input
							v-model.trim="formData.version"
							:maxlength="30"
							placeholder="请输入用户版本"
						/>
					</FormItem>
				</Col>
				<!--<Col span="24">-->
				<!--<FormItem-->
				<!--label="版本日期"-->
				<!--prop="versionTime">-->
				<!--<DatePicker-->
				<!--type="date" placeholder="请选择版本日期"-->
				<!--style="width: 100%"-->
				<!--v-model="formData.versionTime"-->
				<!--format="yyyy-MM-dd">-->
				<!--</DatePicker>-->
				<!--</FormItem>-->
				<!--</Col>-->
				<Col span="24">
					<FormItem label="上传文件" prop="fileList">
						<Upload
							:headers="{ token: $store.state.user.token }"
							:before-upload="beforeUpload"
							:default-file-list="formData.fileList"
							action="/"
							:accept="acceptType"
							:multiple="multiple"
							:show-upload-list="false"
						>
							<Button>请选择文件</Button>
							<!--只在流程文档库界面显示该提示-->
							<div
								v-if="$route.name === 'projectDocumentProcess'"
								@click.stop="
									() => {
										return false
									}
								"
								style="
									color: #ff9800;
									margin-top: 8px;
									font-size: 12px;
									line-height: 20px;
								"
							>
								<!--<Icon type="ios-alert" style="font-size: 14px;"/>-->
								<p>1. 文件大小建议在2G以内，最大不超过10G；</p>
								<p>
									2.
									文件名称（含后缀名），长度请限制在英文数字255字符以内，中文85字以内
								</p>
							</div>
						</Upload>
					</FormItem>
				</Col>
				<Col span="24" v-for="(item, index) in formData.fileList" :key="index">
					<div class="upload-file-list">
						<Icon type="ios-document" /> {{ item.file.name }} ({{
							formatBytes(item.file.size, 2)
						}})
						<template v-if="$route.name === 'projectDocumentProcess'">
							<Icon
								type="ios-close-circle-outline"
								v-if="item.uploadStatus === 100"
								class="close-icon"
								title="删除文件"
								@click="handleDelFile(item, index)"
								size="18"
							/>
							<Progress
								:percent="item.uploadStatus"
								:stroke-width="5"
								:stroke-color="['#108ee9', '#87d068']"
							/>
						</template>
						<template v-else>
							<Icon class="success" type="md-checkmark" v-if="item.id" />
							<Icon v-else type="md-warning" class="error" />
						</template>
					</div>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import { formDataToMD5 } from "@/utils/util"
import { mapState } from "vuex"
import processApi from "@/api/project/process"
import moment from "moment"

const { apiGetProcessing } = processApi

export default {
	name: "uploadFile",
	props: {
		visible: {
			type: Boolean
		},
		multiple: {
			type: Boolean,
			default: false
		},
		docfileId: {
			type: String,
			default: ""
		},
		pid: {},
		replaceId: {
			type: String,
			default: ""
		},
		defaultDoc: {
			type: Object,
			default: () => ({})
		},
		fileOpFunc: {
			type: Object,
			default: () => ({})
		},
		isRoot: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			loading: false,
			isSave: "", // 是否临时上传 0=否、1=是,默认0
			formData: {
				version: "",
				// versionTime: '',
				isRelateProcess: 0,
				processIds: [],
				procInstId: "",
				fileList: []
			},
			rules: {
				name: {
					required: true,
					message: "文件夹名称不能为空",
					trigger: "blur"
				},
				version: {
					required: !!(this.defaultDoc.id === "2"),
					message: "用户版本不能为空",
					trigger: "blur"
				},
				processIds: {
					required: true,
					type: "array",
					message: "请选择上传文件对应的流程",
					trigger: "blur change"
				},
				fileList: {
					required: true,
					type: "array",
					message: "上传文件不能为空",
					trigger: "blur change"
				}
			},
			title: "",
			projectId: this.$route.params.projectId
				? this.$route.params.projectId
				: "",
			processList: [],
			notel: null,
			newFile: null,
			isOkArr: []
		}
	},
	computed: {
		...mapState("config", [
			"IS_ONLY_PDF",
			"IN_HOSPITAL",
			"OUT_HOSPITAL",
			"IS_FILE_REMIND"
		]),
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		},
		acceptType() {
			if (
				(this.defaultDoc.id === "2" || this.defaultDoc.id === "3") &&
				this.IS_ONLY_PDF
			) {
				return "application/pdf"
			}
			if (this.defaultDoc.id === "1") {
				if (
					this.$store.state.user.info.orgType === "104" &&
					!this.IN_HOSPITAL
				) {
					return "application/pdf"
				}
				if (
					this.$store.state.user.info.orgType !== "104" &&
					!this.OUT_HOSPITAL
				) {
					return "application/pdf"
				}
			}
			return "*"
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {
					version: "",
					// versionTime: '',
					isRelateProcess: 0,
					processIds: [],
					procInstId: "",
					fileList: []
				}
				if (this.defaultDoc.id === "2") {
					// 流程文档库，上传文件，默认版本号1.0；其他不设默认值
					this.formData.version = "1.0"
				}
				this.isOkArr = []
				this.title = "上传文件"
				if (this.docfileId && this.replaceId) {
					this.title = "替换文件"
				}
			}
		},
		"formData.fileList": {
			handler(val, oldVal) {
				const idItemArr = val.filter(item => item.id)
				if (
					idItemArr.length === val.length &&
					idItemArr.length === this.isOkArr.length
				) {
					console.log("ready")
					this.loading = false
				}
			},
			deep: true
		}
	},
	created() {
		this.initProcess()
	},
	mounted() {
		document.body.classList.add("body-not-found")
	},
	methods: {
		strCode(str) {
			// 获取字符串的字节数
			let count = 0 // 初始化字节数递加变量并获取字符串参数的字符个数
			if (str) {
				// 如果存在字符串，则执行
				const len = str.length
				for (let i = 0; i < len; i++) {
					// 遍历字符串，枚举每个字符
					if (str.charCodeAt(i) > 255) {
						// 字符编码大于255，说明是双字节字符(即是中文),linux 汉字要算3个字符
						count += 3 // 则累加3个
					} else {
						count++ // 否则递加一次
					}
				}
				return count // 返回字节数
			}
			return 0 // 如果参数为空，则返回0个
		},
		changeCascader(value, selectedData) {
			if (value.length) {
				this.formData.procInstId = value[1]
			} else {
				this.formData.procInstId = ""
			}
		},
		// 初始化流程下拉列表
		initProcess() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProcessing({ projectId: this.projectId })
				if (res && res.data) {
					this.processList = res.data
				}
				this.loading = false
			})
		},
		switchChange(value, switchtype) {
			this.formData[switchtype] = value
		},
		// 删除文件
		handleDelFile(item, index) {
			this.$Modal.confirm({
				title: "提示",
				content: "确认删除该文件吗？",
				onOk: () => {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.fileOpFunc.deleteProFile) {
							res = await this.fileOpFunc.deleteProFile({
								projectId: this.projectId,
								docfileId: item.id
							})
						}

						if (res) {
							this.formData.fileList.splice(index, 1)
							this.isOkArr.splice(index, 1)
							this.$Message.success("删除成功！")
						}
						this.loading = false
					})
				}
			})
		},
		beforeUpload(file) {
			const nowTime = new Date().getTime()
			if (this.acceptType === "application/pdf") {
				// 判断是否限制只能上传pdf格式
				const nameArr = file.name.split(".")
				if (nameArr[nameArr.length - 1].toUpperCase() !== "PDF") {
					this.$Message.error("只能上传pdf格式的文件")
					return false
				}
			}
			if (this.defaultDoc.id === "2" && this.strCode(file.name) > 255) {
				this.$Message.error(
					"文件名称过长，请限制在英文数字255字符以内，中文85字以内（含后缀名）!"
				)
				return false
			}
			const size = file.size / 1024
			const filemaxsize = 1024 * 1024 * 10 // 10G
			if (size > filemaxsize) {
				this.$Message.error("附件大小不能大于10G")
				return false
			}
			// 是否文件提交提醒
			if (this.defaultDoc.id === "2" && this.IS_FILE_REMIND) {
				this.$Modal.confirm({
					title: "线下文件是否提交",
					okText: "是",
					cancelText: "否"
				})
			}

			if (this.$route.name === "projectDocumentProcess") {
				// 暂存上传文件
				this.loading = true
				this.formData.fileList.push({ file, nowTime })
				formDataToMD5(file, md5Str => {
					this.newFile = Object.assign({ nowTime }, file)
					this.formData.fileList.forEach(item => {
						if (item.nowTime === nowTime) {
							item.md5 = md5Str
						}
					})
					this.$refs.form.validateField("fileList")
					this.submit()
				})
			} else {
				formDataToMD5(file, md5Str => {
					this.formData.fileList.push({ file, md5: md5Str })
					this.$refs.form.validateField("fileList")
				})
			}
			return false
		},
		uploadRequest(ob, type) {
			if (this.docfileId && this.replaceId) {
				const formData = this.getFormData(ob, this.replaceId, type)
				return this.fileOpFunc.replaceFile({
					body: formData,
					projectId: this.projectId,
					docfileId: this.replaceId
				})
			}
			const formData = this.getFormData(ob, this.docfileId, type)
			if (this.$route.name === "projectDocumentProcess" && type === 1) {
				// 临时上传，展示进度条
				return this.fileOpFunc.uploadDraftFile(
					{
						body: formData,
						projectId: this.projectId
					},
					e => this.onProgress(e, ob)
				)
			}
			return this.fileOpFunc.uploadFile({
				body: formData,
				projectId: this.projectId
			})
		},
		getFormData(ob, typeFileId, type) {
			const formData = new FormData()
			formData.append("file", ob.file)
			formData.append("md5", ob.md5)
			// formData.append('version', this.formData.version);
			// formData.append('versionTime', this.formData.versionTime ? moment(this.formData.versionTime).format('YYYY-MM-DD') : '');
			formData.append("isSubmit", ob.isSubmit)
			formData.append("id", this.projectId)
			formData.append("pid", typeFileId)
			formData.append("procInstId", this.formData.procInstId)
			formData.append("isTempUpload ", type)
			return formData
		},
		// 文件暂存 流程文档库
		submit() {
			this.isSave = 1 // 是否临时上传 0=否、1=是,默认0
			this.$asyncDo(async () => {
				let currentIndex = 0
				const pList = this.formData.fileList
					.filter((item, index) => {
						if (item.nowTime === this.newFile.nowTime) {
							currentIndex = index
							return true
						}
						return false
					})
					.map(item => this.uploadRequest(item, 1))
				pList.forEach(async (itemApi, index) => {
					this.loading = true
					const res = await itemApi
					if (res) {
						if (res.code === 0) {
							this.$set(this.formData.fileList[currentIndex], "id", res.data)
							this.isOkArr.push("true")
							this.$Message.success("上传成功")
						} else {
							this.isOkArr.push(`false_${index}`)
							this.$Message.success("上传失败")
						}
					}
				})
			})
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					if (!this.formData.fileList.length) {
						this.$Message.error("上传文件不能为空")
						return
					}
					this.save()
				}
			})
		},
		// 文件提交
		save() {
			this.isSave = 0
			if (this.$route.name === "projectDocumentProcess") {
				this.$asyncDo(async () => {
					this.loading = true
					const ids = this.formData.fileList.map(item => item.id)
					const res = await this.fileOpFunc.uploadFile({
						pid: this.pid,
						projectId: this.projectId,
						ids,
						version: this.formData.version
					})
					if (res) {
						this.$Message.success("保存成功")
						document.body.classList.remove("body-not-found")
						this.$emit("onOk")
					}
					this.loading = false
				})
			} else {
				this.$asyncDo(async () => {
					this.loading = true
					const pList = this.formData.fileList.map(item =>
						this.uploadRequest(item, 0)
					)
					const result = await Promise.all([...pList])
					if (result) {
						result.forEach((item, index) => {
							if (item.code === 0) {
								this.$set(this.formData.fileList[index], "id", item.data)
							}
						})

						if (result.filter(o => o.code !== 0).length) {
							this.$Message.success("保存失败")
						} else {
							this.$Message.success("保存成功")
							document.body.classList.remove("body-not-found")
							this.$emit("onOk")
						}
					}
					this.loading = false
				})
			}
		},
		onProgress(e, item) {
			if (this.isSave !== 1) {
				this.$set(item, "uploadStatus", 100)
				return
			}
			if (e.loaded === e.total) {
				this.$set(item, "uploadStatus", 100)
				// this.isOkArr.push('true');
				// console.log('this.isokarr', this.isOkArr);
				// this.$Message.success('上传成功');
				return
			}
			let ot = new Date().getTime()
			let oloaded = 0
			// 上传中回调
			const nt = new Date().getTime()
			const pertime = (nt - ot) / 1000
			const perload = e.loaded - oloaded // 计算该分段上传的文件大小，单位b
			ot = new Date().getTime()
			oloaded = e.loaded // 重新赋值已上传文件大小，用以下次计算
			// 上传速度计算
			let speed = perload / pertime // 单位b/s
			const bspeed = speed
			let units = "b/s" // 单位名称
			if (speed / 1024 > 1) {
				speed /= 1024
				units = "k/s"
			}
			if (speed / 1024 > 1) {
				speed /= 1024
				units = "M/s"
			}
			speed = speed.toFixed(1)
			const percent = Math.round((e.loaded / e.total) * 100)
			this.$set(item, "uploadStatus", percent)
		},
		onCancel() {
			this.$asyncDo(async () => {
				this.loading = true
				if (
					this.$route.name === "projectDocumentProcess" &&
					this.fileOpFunc.deleteDraftFile
				) {
					const res = await this.fileOpFunc.deleteDraftFile({
						projectId: this.projectId,
						pid: this.pid
					})
					if (res) {
						document.body.classList.remove("body-not-found")
						this.$emit("onCancel")
					}
				} else {
					document.body.classList.remove("body-not-found")
					this.$emit("onCancel")
				}
				this.loading = false
			})
		},
		// 文件大小转换
		// c 参数：表示要被转化的容量大小，以字节为单
		// b 参数：表示如果转换时出小数，四舍五入保留多少位 默认为2位小数
		formatBytes(a, b) {
			if (a === 0) return "0 B"
			const c = 1024
			const d = b || 2
			const e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
			const f = Math.floor(Math.log(a) / Math.log(c))
			return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`
		}
	}
}
</script>

<style lang="less">
.body-not-found {
	.ivu-cascader-not-found-tip {
		width: 310px;
	}
}
.upload-file-wrap {
	.close-icon {
		cursor: pointer;
		margin-left: 15px;
		&:hover {
			color: #2d8cf0;
		}
	}
}
.upload-file-list {
	padding: 5px;
	font-size: 13px;
}
.upload-file-list .success {
	color: #19be6b;
	margin-left: 5px;
}
.upload-file-list .error {
	color: #ed4014;
	margin-left: 5px;
}
</style>
