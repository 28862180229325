<template>
	<Modal
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header">
			<h3>
				{{ type === "view" ? "查看权限" : "下载权限" }}
				<span
					style="
						margin-left: 10px;
						color: #2d8cf0;
						font-size: 12px;
						font-weight: normal;
					"
				>
					提示：全不选，视为全选</span
				>
			</h3>
		</div>
		<CheckboxGroup v-model="roleIds">
			<div v-for="item in roleList" :key="item.id" class="role-list-item">
				<Checkbox :label="item.id" :disabled="item.disabled">
					<span>{{ item.name }}</span>
				</Checkbox>
			</div>
		</CheckboxGroup>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
export default {
	name: "InfoPermission",
	props: ["visible", "id", "fileOpFunc", "type", "isRoot", "pid"],
	data() {
		return {
			loading: false,
			roleList: [],
			roleIds: [], // 选中的roleId。
			isParentCheckedAll: true // 父级是否全选
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.roleIds = []
				const pId = String(this.pid) === "1" ? "" : this.pid
				this.$asyncDo(async () => {
					this.loading = true
					const res = await this.fileOpFunc.apiGetPermission({
						docfileId: this.id,
						grantType: this.type,
						pid: pId
					})
					if (res && res.data) {
						this.roleList = res.data
						this.roleIds = res.data
							.filter(el => el.checked)
							.map(item => item.id)
						this.getCheckedAll(
							this.type,
							this.type === "view"
								? "parentViewChecked"
								: "parentDownLoadChecked"
						)
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		getCheckedAll(type, parentChecked) {
			const arr = []
			this.roleList.forEach(item => {
				if (this.type === type && item[parentChecked]) {
					arr.push(item)
				}
			})
			if (arr.length === 0 || arr.length === this.roleList.length) {
				this.isParentCheckedAll = true
			} else {
				this.isParentCheckedAll = false
			}
			if (!this.isRoot && !this.isParentCheckedAll && this.roleIds.length) {
				this.roleList = this.roleList.map(item => {
					const el = item
					if (
						!item.checked &&
						((this.type === "view" && !item.parentViewChecked) ||
							(this.type === "download" && !item.parentDownLoadChecked))
					) {
						el.disabled = true
					} else {
						el.disabled = false
					}
					return el
				})
			}
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$asyncDo(async () => {
				if (!this.isRoot && !this.isParentCheckedAll && !this.roleIds.length) {
					this.$Message.error("至少选择一个角色")
					return
				}
				this.loading = true
				const res = await this.fileOpFunc.apiSetPermission({
					docfileId: this.id,
					roleIds: this.roleIds,
					grantType: this.type
				})
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
		}
	}
}
</script>

<style scoped></style>
